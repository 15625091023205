<template>
	<div v-if="games.length > 0">
		<div class="grid">
			<div
				v-for="game in games"
				:key="game.uid">
					<router-link
						tag="a"
						:to="'/play/' + game.uid"
						class="img_cover">
						<img
							:src="$util.bindUrlCdn(game.thumbnail, 'c300')"
							:alt="`thumbnail of game named ${game.name}`">
					</router-link>

					<div class="f-list pd:sm" style="padding-left: 0; padding-right: 0; align-items: start">
						<div class="game-name">{{ game.name }}</div>

						<a class="btn-game-fav f-list-right">
							<img
								src="@/assets/images/icon-favourite-outline.png"
								alt="icon of favourite in love shape">
						</a>
					</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'component-games-list-grid',
	inject: ['$util'],
	props: {
		games: Array
	}
}
</script>

<style lang="scss" scoped>
$item-per-row: 2;

.grid {
	display: flex;
	flex-wrap: wrap;
	margin: -($pd / 2);

	> * {
		flex-basis: 100% / $item-per-row;

		@media screen and (min-width: #{map-get($breakpoints, 'md')}) {
			& {
				flex-basis: 100% / ($item-per-row + 1);
			}
		}

		padding: $pd / 2;
		padding-bottom: 0;
		color: $color-accent-pink;

		&:last-child {
			padding-bottom: $pd / 2;
		}

		> .img_cover {
			line-height: 0;

			img { border-radius: $pd / 2 }
		}
	}

	.game-name {
		font-weight: 600;
	}

	.btn-game-fav {
		display: none; // unfinished

		img {
			height: 1em;
			width: auto;
		}
	}
}
</style>
