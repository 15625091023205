<template>
	<main>
		<div class="app-heading pd:md">
			<div class="app-title" v-t="'popular'"></div>
			<hr>
		</div>

		<div v-if="isLoading" class="preloader"></div>

		<div class="pd:md" style="padding-top: 0">
			<component-games-list-grid :games="games"/>
		</div>
	</main>
</template>

<script>
import ComponentGamesListGrid from '@/components/component-games-list-grid'

export default {
	name: 'popular',
	components: {
		ComponentGamesListGrid
	},
	data () {
		return {
			isLoading: true,
			games: []
		}
	},
	methods: {
		async init () {
			const payload = []

			const modelPopular = 'get_content_popular'
			const payloadPopular = {
				game: [
					{ [modelPopular]: [{ args: [{ limit: 99 }] }] }
				]
			}

			payload.push(payloadPopular)

			const responses = await this.$store.dispatch('api/fetch', {
				method: 'POST',
				payload: payload
			})

			if (responses.game[modelPopular][0][0].code === 2001) {
				this.games = responses.game[modelPopular][0][0].data
			}

			this.isLoading = false
		}
	},
	async created () {
		await this.init()
	}
}
</script>

<style lang="scss" scoped>
main {
	background-color: $color-background;
	color: $color-foreground;

	.app-heading {
		.app-title {
			text-transform: none;
		}

		hr {
			margin-bottom: 0;
			color: $color-accent-pink;
			border-color: $color-accent-pink;
			border-width: .1em;
		}
	}
}
</style>
